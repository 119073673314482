<template>
  <div class="Table mt-8">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="waypoints"
      :single-select="isOwn"
      item-key="waypointId"
      :show-select="isOwn"
      sort-by="waypointNumber"
      class="elevation-1"
      :loading="loading"
    >

      
      <template v-slot:item.thumbnail="{ item }  ">
        <TableThumbnail
          :item="item"
          :change="toggleChange"
          isWaypoint="true"
          class="my-2 mx-auto "
          style="width:150px;"
         />
      </template>

      <template v-slot:item.contents="{ item } ">
       <ul v-if="item.contents[0]" :style="$vuetify.breakpoint.xs?'width:100%':'width:80%'">
        <li  v-for="(val,index) in item.contents" :key="index" style="width:100%;">
           <div  class="d-flex">
            
                <!-- <v-btn v-if="isOwn"
                  text 
                  small 
                  dense  
                  @click="changeDynamic(item,index,val)"
                  class="ma-0 pa-1"
                >

                 <span :style="val.dynamic?'color:black;':'color:grey;'">動的</span> <span style="color:grey;">/</span> <span :style="!val.dynamic?'color:black;':'color:grey;'">静的</span>
                </v-btn> -->
   

              
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }"> 
                      <v-btn v-if="isOwn"
                        v-bind="attrs"
                        v-on="on"
                        icon 
                        small 
                        dense  
                        @click="changeDynamic(item,index,val)"
                        class="ma-0 pa-1"
                      >
                      <v-icon v-if="val.dynamic" small dense>{{icons.dynamic}}</v-icon>
                      <v-icon v-else small dense>{{icons.static}}</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="val.dynamic">動的 </span>
                  <span v-else>静的 </span>
              </v-tooltip>
        

            <v-btn  text  color="grey" small dense @click="searchTable(val)">
              <span v-if="val.filename">{{ val.filename }}</span>
              <span v-else> {{val.title}}</span>
              <v-icon small dense color="grey">{{icons.tableSearch}}</v-icon>
            </v-btn>
          </div>
        </li>
       </ul>
       <div v-else style="opacity:55%;">コンテンツなし</div>
      </template>


    </v-data-table>

  </div>
</template> 

<script>

const TableThumbnail = () => import('@/components/TableThumbnail.vue');

// let cancel;
import axios from "axios"
import { mdiOpenInNew,mdiCursorMove,mdiHandBackRightOutline,mdiHandBackRightOffOutline } from '@mdi/js';
export default {
  name: 'WaypointsTable',
  props:['resetSelect','isOwn'],
  components :{
    TableThumbnail,
  },
  data () {
    return {
      icons:{"tableSearch":mdiOpenInNew,"cursorMove":mdiCursorMove,"dynamic":mdiHandBackRightOutline,"static":mdiHandBackRightOffOutline},
      selected: [],
      items:[],
      waypoints:[],
      changedWaypoints:[],
      waypointsId:[],
      thumbnails:[],
      changeDynamicIdList:{},
      loading:true,
      toggleChange:false,
      env:process.env.VUE_APP_ENV,
    }
  },
  computed: {
    headers () {

      if(this.isOwn){
         return [
        {
          text: 'サムネイルにする',
          value: 'data-table-select',
          align: 'center',
          width: "10%",
        },
        {
          text: 'ウェイポイント番号',
          align: 'center',
          sortable: true,
          value: 'waypointNumber',
          width: "20%",
        },
        {
          text: 'サムネイル',
          align: 'center',
          value: 'thumbnail',
          width: "20%",
        },
        {
          text: 'コンテンツ',
          align: 'center',
          value: 'contents',
          width: "50%",
        },
      ]
      }else{
        return [
        {
          text: 'ウェイポイント番号',
          align: 'center',
          sortable: true,
          value: 'waypointNumber',
          width: "30%",
        },
        {
          text: 'サムネイル',
          align: 'center',
          value: 'thumbnail',
          width: "20%",
        },
        {
          text: 'コンテンツ',
          align: 'center',
          value: 'contents',
          width: "40%",
        },
      ]
      }
     
    },
  },

   
  created() {
    this.getData();
  },



   
  methods : {
    //ウェイポイント一覧の取得
     getData:  function(){
        axios({
          method: "GET",
          url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.$route.params.id}/waypoints`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
        },
        ).then((response) => {
          this.waypoints = response.data.results;
          for(let i = 0 ; i < this.waypoints.length; i++ ){
            this.waypointsId.push(this.waypoints[i].waypointId)
          }
          this.getDataThumbnails();

        }).catch(reason => {
          console.log(reason.response)
        });
        
    },
    
    //ウェイポイントのサムネイル一覧の取得
    getDataThumbnails :async function(){
       await axios({
          method: "GET",
          url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/navis/${this.$route.params.id}/waypoints/thumbnails`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"WaypointId":JSON.stringify(this.waypointsId), "Content-Type":"application/json" },
        },
        ).then((response) => {
          this.thumbnails = response.data.results;
          for(let i = 0 ; i < this.thumbnails.length; i++ ){
            let id = this.thumbnails[i].waypointId;
            let thumb = this.thumbnails[i].thumbnail
            for(let j = 0 ; j < this.waypoints.length; j++){
              if(this.waypoints[j].waypointId == id){
                this.waypoints[j].thumbnail = "data:image/png;base64,"+thumb; 
              }
            }
          } 
          // console.log(this.waypoints)

          this.changedWaypoints = JSON.stringify(this.waypoints); // JSON文字列化
          this.changedWaypoints = JSON.parse(this.changedWaypoints); // JSON文字列化したものを戻す
          this.loading = false;
          this.toggleChange = !this.toggleChange;

        }).catch(reason => {
          console.log(reason.response)
        });

    },

    changeDynamic(item,i,val){
      console.log(this.waypoints)
      let index = this.items.indexOf(item);
      this.waypoints[index].contents[i].dynamic = !val.dynamic; 
      // if (this.waypoints[index].contents[i].dynamic != this.changedWaypoints[index].contents[i].dynamic){
      //   this.changeDynamicIdList[`${this.waypoints[index].contents[i].id}`] = this.waypoints[index].contents[i].dynamic;
      // }else{
      //   delete this.changeDynamicIdList[`${this.waypoints[index].contents[i].id}`]
      // }
      // console.log(this.objectEquals(this.waypoints,this.changedWaypoints));
      if( !this.objectEquals(this.waypoints,this.changedWaypoints) ){
        this.$emit('dynamicIdList',this.waypoints);
      }else{
        this.$emit('dynamicIdList',[]);
      }


    },

    objectEquals(a, b){
     
      if(a === b){
          return true;
      }

      for(let i = 0; i < a.length; i++){
        let nest_a = a[i].contents;
        let nest_b = b[i].contents;
        for(let n = 0; n < nest_a.length; n++){
           // 比較対象双方のキー配列を取得する（順番保証のためソートをかける）
          const aKeys = Object.keys(nest_a[n]).sort();
          const bKeys = Object.keys(nest_b[n]).sort();
         
          // 比較対象同士のキー配列を比較する
          if(aKeys.toString() !== bKeys.toString()){
              // キーが違う場合はfalse
              return false;
          }
           // 値をすべて調べる。
          const wrongIndex = aKeys.findIndex(function(value){
            return nest_a[n][value] !== nest_b[n][value];
          });

          // 合致しないvalueがなければ、trueを返す。
          if(wrongIndex !== -1){
            return false
          }
        }
     
      }

      return true
     
    },

    searchTable(waypoint){
      let tag = waypoint.tag;
      console.log(tag)
      let route = tag.substr(2).toLowerCase();
      console.log(route)
      if(waypoint.filename){
        this.$router.push({
          name: route,
          query: { search:waypoint.filename }
        })
      }
      else{
        this.$router.push({
          name: route,
          query: { search:waypoint.title }
        })
      }
      
 
      
    },

    completedSave(){
      this.changedWaypoints = JSON.stringify(this.waypoints); // JSON文字列化
      this.changedWaypoints = JSON.parse(this.changedWaypoints); // JSON文字列化したものを戻す
    },

 
  },

  watch: {
      // waypoints: {
      //   handler(){
      //     if( JSON.stringify(this.waypoints) != JSON.stringify(this.changedWaypoints)){
      //       console.log("changeWaypoint")
      //     }else{
      //       console.log("No changeWaypoint")
      //     }
          
      //   },
      //   deep: true,
      // },

      // changeDynamicIdList: {
      //   handler:function(newVal){
      //     console.log(newVal)
      //     this.$emit('dynamicIdList',newVal);
      //   },
      //   deep: true,
      // },

    loading: function(){
      this.items = this.waypoints;
      this.selected = [];

    },

    resetSelect: function(){
      this.selected = [];
    } ,

    selected: function(newVal){
      if(newVal[0]){
        this.$emit('selectedWaypointId',newVal[0].waypointId,newVal[0].thumbnail)
      }else{
        this.$emit('selectedWaypointId',"no")
      }
    } 
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  padding: 0 ;
  margin:auto;
  text-align: center;
  overflow: auto;
  height:110px;
}
li {
  margin: 0 10  ;
  width: 50%;
  text-align: left;
}
a {
  color: #42b983;
}

.text-start {
  padding: none;
}

v-data-table >>> div > table {
  border-spacing: 0 0.5rem;
}


</style>
